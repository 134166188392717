.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.exchange-detail .detail-cont {
  width: 100%;
}
.exchange-detail .detail-cont .detail-item {
  padding: 0.1rem 0.2rem 0 0.2rem;
  background: #fff;
}
.exchange-detail .detail-cont .detail-item .layout-bottom {
  position: relative;
  overflow: hidden;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img {
  float: left;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img img {
  width: 0.96rem;
  height: 0.96rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt {
  height: 0.96rem;
  margin-left: 0.2rem;
  float: left;
  position: relative;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .number {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  bottom: 0.05rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .name {
  font-size: 0.28rem;
  color: #333;
  width: 5rem;
  padding: 0.09rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.exchange-detail .detail-cont .detail-item .prize-time {
  font-size: 0.28rem;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0 0.2rem 0;
}
.exchange-detail .detail-cont .account {
  font-size: 0.28rem;
  color: #666;
  padding: 0 0.2rem;
  margin-top: 0.2rem;
  background: #fff;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}
.exchange-detail .detail-cont .account .tip {
  padding-top: 0.1rem;
}
.exchange-detail .detail-cont .account .tip .tip-con {
  font-size: 0.24rem;
  color: #999;
  padding: 0.1rem 0;
}
.exchange-detail .detail-cont .account .input {
  font-size: 0.28rem;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.1rem 0 0.2rem 0;
  /* Firefox 4-18 */
  /* Firefox 19-50 */
  /* - Internet Explorer 10–11
          - Internet Explorer Mobile 10-11 */
  /* Edge (also supports ::-webkit-input-placeholder) */
  /* CSS Working Draft */
}
.exchange-detail .detail-cont .account .input .input-layout {
  width: 4rem;
  height: 0.6rem;
  background: #F2F2F2;
  border-radius: 0.64rem;
  padding: 0 0.2rem;
  line-height: 0.6rem;
}
.exchange-detail .detail-cont .account .input ::-webkit-input-placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input :-moz-placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input ::-moz-placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input :-ms-input-placeholder {
  color: #999 !important;
  font-weight: 400 !important;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input ::-ms-input-placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input ::placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .confirm {
  margin: 0.28rem 0.2rem 0;
  height: 0.64rem;
  line-height: 0.64rem;
  background: #FA5212;
  text-align: center;
  font-size: 0.28rem;
  border-radius: 0.64rem;
  color: #fff;
}
.exchange-detail .account-recharge {
  font-size: 0.28rem;
  color: #666;
  height: 0.8rem;
  background: #fff;
  padding: 0 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.2rem;
}
.exchange-detail .success-tip {
  margin-top: 0.2rem;
  padding: 0 0.2rem;
  background: #fff;
}
.exchange-detail .success-tip .tip {
  padding-top: 0.1rem;
}
.exchange-detail .success-tip .tip .tip-con {
  font-size: 0.24rem;
  color: #999;
  padding: 0.1rem 0;
}
.exchange-detail .success-tip .exchange-kami {
  font-size: 0.28rem;
  color: #666;
}
.exchange-detail .success-tip .exchange-kami .exchange-detail,
.exchange-detail .success-tip .exchange-kami p {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.exchange-detail .success-tip .exchange-kami .kami-con {
  font-size: 0.24rem;
  color: #999;
  padding-bottom: 0.2rem;
}
.exchange-detail .success-tip .exchange-kami img {
  width: 0.4rem;
  height: 0.4rem;
}
.exchange-detail .success-tip .exchange-kami .copy-code-button {
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  background: url('~@/assets/haiyuansu/copy.png') no-repeat;
  background-size: 100% 100%;
  margin-left: 0.2rem;
}
.exchange-detail .success-tip .exchange-kami .exchange-txt {
  display: flex;
  align-items: center;
}
