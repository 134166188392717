.border-none[data-v-0ba1103b]:before {
  display: none;
}
.border-none[data-v-0ba1103b]:after {
  display: none;
}
.flex-fix[data-v-0ba1103b] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-0ba1103b]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-0ba1103b] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-0ba1103b] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-0ba1103b] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-0ba1103b] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-0ba1103b] {
  *zoom: 1;
}
.clear-fix[data-v-0ba1103b]:before,
.clear-fix[data-v-0ba1103b]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.exchange-detail .detail-cont[data-v-0ba1103b] {
  width: 100%;
}
.exchange-detail .detail-cont .detail-item[data-v-0ba1103b] {
  padding: 0.1rem 0.2rem 0 0.2rem;
  background: #fff;
}
.exchange-detail .detail-cont .detail-item .layout-bottom[data-v-0ba1103b] {
  position: relative;
  overflow: hidden;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img[data-v-0ba1103b] {
  float: left;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img img[data-v-0ba1103b] {
  width: 0.96rem;
  height: 0.96rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt[data-v-0ba1103b] {
  height: 0.96rem;
  margin-left: 0.2rem;
  float: left;
  position: relative;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .number[data-v-0ba1103b] {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  bottom: 0.05rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .name[data-v-0ba1103b] {
  font-size: 0.28rem;
  color: #333;
  width: 5rem;
  padding: 0.09rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.exchange-detail .detail-cont .detail-item .prize-time[data-v-0ba1103b] {
  font-size: 0.28rem;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.2rem 0 0.2rem 0;
}
.exchange-detail .detail-cont .account[data-v-0ba1103b] {
  font-size: 0.28rem;
  color: #666;
  padding: 0 0.2rem;
  margin-top: 0.2rem;
  background: #fff;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
}
.exchange-detail .detail-cont .account .tip[data-v-0ba1103b] {
  padding-top: 0.1rem;
}
.exchange-detail .detail-cont .account .tip .tip-con[data-v-0ba1103b] {
  font-size: 0.24rem;
  color: #999;
  padding: 0.1rem 0;
}
.exchange-detail .detail-cont .account .input[data-v-0ba1103b] {
  font-size: 0.28rem;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.1rem 0 0.2rem 0;
  /* Firefox 4-18 */
  /* Firefox 19-50 */
  /* - Internet Explorer 10–11
          - Internet Explorer Mobile 10-11 */
  /* Edge (also supports ::-webkit-input-placeholder) */
  /* CSS Working Draft */
}
.exchange-detail .detail-cont .account .input .input-layout[data-v-0ba1103b] {
  width: 4rem;
  height: 0.6rem;
  background: #F2F2F2;
  border-radius: 0.64rem;
  padding: 0 0.2rem;
  line-height: 0.6rem;
}
.exchange-detail .detail-cont .account .input[data-v-0ba1103b] ::-webkit-input-placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input[data-v-0ba1103b] ::-moz-placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input[data-v-0ba1103b] :-ms-input-placeholder {
  color: #999 !important;
  font-weight: 400 !important;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input[data-v-0ba1103b] ::-ms-input-placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .detail-cont .account .input[data-v-0ba1103b] ::placeholder {
  color: #999;
  font-weight: 400;
  font-size: 0.24rem;
}
.exchange-detail .confirm[data-v-0ba1103b] {
  margin: 0.28rem 0.2rem 0;
  height: 0.64rem;
  line-height: 0.64rem;
  background: #FA5212;
  text-align: center;
  font-size: 0.28rem;
  border-radius: 0.64rem;
  color: #fff;
}
.exchange-detail .account-recharge[data-v-0ba1103b] {
  font-size: 0.28rem;
  color: #666;
  height: 0.8rem;
  background: #fff;
  padding: 0 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0.2rem;
}
.exchange-detail .success-tip[data-v-0ba1103b] {
  margin-top: 0.2rem;
  padding: 0 0.2rem;
  background: #fff;
}
.exchange-detail .success-tip .tip[data-v-0ba1103b] {
  padding-top: 0.1rem;
}
.exchange-detail .success-tip .tip .tip-con[data-v-0ba1103b] {
  font-size: 0.24rem;
  color: #999;
  padding: 0.1rem 0;
}
.exchange-detail .success-tip .exchange-kami[data-v-0ba1103b] {
  font-size: 0.28rem;
  color: #666;
}
.exchange-detail .success-tip .exchange-kami .exchange-detail[data-v-0ba1103b],
.exchange-detail .success-tip .exchange-kami p[data-v-0ba1103b] {
  padding: 0.1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}
.exchange-detail .success-tip .exchange-kami .kami-con[data-v-0ba1103b] {
  font-size: 0.24rem;
  color: #999;
  padding-bottom: 0.2rem;
}
.exchange-detail .success-tip .exchange-kami img[data-v-0ba1103b] {
  width: 0.4rem;
  height: 0.4rem;
}
.exchange-detail .success-tip .exchange-kami .copy-code-button[data-v-0ba1103b] {
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  background: url('~@/assets/haiyuansu/copy.png') no-repeat;
  background-size: 100% 100%;
  margin-left: 0.2rem;
}
.exchange-detail .success-tip .exchange-kami .exchange-txt[data-v-0ba1103b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
